import Api from '@/axios'
export default {
  getAllReson (pageNumber) {
    return Api().get(`memberships/settings/roc/reason?page=${pageNumber}`)
  },
  getAllGroups () {
    return Api().get('memberships/settings/roc/reason/create')
  },
  saveReason (payload) {
    return Api().post('memberships/settings/roc/reason/store', payload)
  }

}
