<template>
  <b-container fluid>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <b-row>
          <b-col lg="12">
            <iq-card>
              <template v-slot:body>
                <div class="new-user-info">
                  <b-row>
                    <b-form-group class="col-md-12" label="Reason:" label-for="reason">
                      <ValidationProvider name="reason" ref="reason" rules="required" v-slot="{ errors }">
                        <b-form-input v-model="info.reason" type="text" placeholder="reason" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                        <div class="invalid-feedback">
                          <span>{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>
                    <b-form-group class="col-md-12" label="Group:" label-for="group">
                      <ValidationProvider name="group" ref="group" rules="required" v-slot="{ errors }">
                        <select  class="form-control" id="group" v-model="info.group">
                          <option selected disabled value="">Choose group...</option>
                          <option v-for="(i, key) in allGroups" :key="key" :value="i">
                            <span>{{i}}</span>
                          </option>
                        </select>
                        <div class="invalid-feedback">
                          <span>{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>
                  </b-row>
                  <div class="d-flex  mt-4 align-items-center justify-content-between">
                    <b-form-checkbox v-model="info.is_active">Is Active</b-form-checkbox>
                    <b-button variant="primary" type="submit" >Add New Reason</b-button>
                  </div>
                </div>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </form>
    </ValidationObserver>
  </b-container>
</template>
<script>
import { core } from '../../config/pluginInit'
import settingService from '../../views/settings/settingService'
export default {
  name: 'reason',
  props: {
  },
  computed: {
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      info: {
        reason: '',
        group: '',
        is_active: ''
      },
      allGroups: []
    }
  },
  methods: {
    onSubmit () {
      settingService.saveReason(this.info).then(res => {
        core.showSnackbar('success', res.data.message)
        this.$bvModal.hide('rocModal')
        this.$emit('refresh')
      }).catch(err => {
        for (const [key, value] of Object.entries(err.response.data.errors)) {
          this.$refs[key].setErrors(value)
        }
      })
    },
    getAllGroups () {
      settingService.getAllGroups().then(res => {
        this.allGroups = res.data.data
      })
    }
  },
  created () {
    this.getAllGroups()
  }
}
</script>
